import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "LandingQR:View": {
    ya: {
      eventName: "landingQrV1_view",
      page: "landingQrV1",
    },
  },
  "Landing:View": {
    ya: {
      eventName: "landingV1_view",
      page: "landingV1",
    },
  },
  "Landing:LinkPwa:Click": {
    ya: {
      eventName: "landingV1_toV1_click",
      page: "landingV1",
      element: "toV1",
    },
  },
  "Landing:Install:Click": {
    ya: {
      eventName: "landingV1_install_click",
      page: "landingV1",
      element: "toV1",
    },
  },
};
