<script setup lang="ts">
import { useRouter } from "vue-router";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VSwitch from "@magnit/core/src/components/VSwitch/VSwitch.vue";
import VListItem from "@magnit/core/src/components/VListItem/VListItem.vue";
import type { ISwitchModelValue } from "@magnit/core/src/components/VSwitch/VSwitch.types";
import { useUserStore } from "~/store/user";

const userStore = useUserStore();

const { send } = AnalyticManager;
const router = useRouter();
const { $toast } = useNuxtApp();

const {
  isOpen,
  isActive,
  name: modalName,
  close: closeModal,
} = useDigitalReceiptModal();

const onDigitalReceiptChange = async (
  _value: ISwitchModelValue,
  event: Event,
) => {
  if (!userStore.profile.email || !userStore.profile.isEmailConfirmed) {
    return;
  }

  const target = event.target as HTMLInputElement;
  send("Modals:DigitalReceipt:Toggle", {
    permission: target.checked ? "True" : "False",
  });

  await userStore.updatePermissions(
    target.checked,
    "isDigitalReceiptPermitted",
  );

  if (userStore.status.permissionsUpdate === "error") {
    $toast.warning({ title: "Что-то пошло не так, попробуйте позже" });
  }
};
const onDigitalReceiptClick = () => {
  if (userStore.profile.email && userStore.profile.isEmailConfirmed) {
    return;
  }

  closeModal();

  router.push({
    path: Routes.ProfileEdit,
    query: {
      email: "edit",
    },
  });
};

const onClose = () => {
  send("Modals:DigitalReceipt:Close");
  closeModal();
};

watch(isOpen, (v) => {
  if (!v) return;
  send("Modals:DigitalReceipt:View");
});
</script>

<template>
  <VModal
    :id="modalName"
    :active="isActive"
    :open="isOpen"
    type="bottom-sheet"
    title="Электронные чеки"
    class="modal-digital-receipt"
    @close="onClose"
  >
    <VListItem :divider="false">
      <template #left>
        <VText font="body-large-accent">
          Получать чеки на e-mail
        </VText>
      </template>
      <template #right>
        <div @click="onDigitalReceiptClick">
          <VSwitch
            :model-value="userStore.permissions.isDigitalReceiptPermitted"
            class="modal-digital-receipt__toggle"
            @update:model-value="onDigitalReceiptChange"
          />
        </div>
      </template>
    </VListItem>
    <VText tag="p" font="body-large-regular" color="secondary">
      Показывайте на кассе карту В1 и получайте чеки на почту. Так они не
      потеряются
    </VText>
  </VModal>
</template>

<style lang="postcss">
.modal-digital-receipt {
  &__toggle {
    margin: 16px 0;
  }
}
</style>
