import { defineStore } from "pinia";
import type { INotification } from "~/components/VNotification/VNotification.types";

export const useAppStore = defineStore("app", () => {
  const notifications = ref<INotification[]>([]);
  const displayLoader = ref(false);
  const displayShopSelect = ref(false);

  return {
    notifications,
    displayLoader,
    displayShopSelect,
  };
});
