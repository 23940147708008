import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Register:Personal:View": {
    ya: {
      eventName: "personal_view",
      page: "personal",
    },
  },
  "Register:Personal:Name:Click": {
    ya: {
      eventName: "personal_name_click",
      page: "personal",
      block: "name",
    },
  },
  "Register:Personal:Birth:Click": {
    ya: {
      eventName: "personal_birth_click",
      page: "personal",
      block: "birth",
    },
  },
  "Register:Personal:Email:Click": {
    ya: {
      eventName: "personal_email_click",
      page: "personal",
      block: "email",
    },
  },
  "Register:Personal:NoticeSms:Click": {
    ya: {
      eventName: "personal_notice_sms_click",
      page: "personal",
      block: "notice",
      element: "sms",
    },
  },
  "Register:Personal:Continue:Click": {
    ya: {
      eventName: "personal_continue_click",
      page: "personal",
      block: "continue",
    },
  },
  "Register:Personal:Back": {
    ya: {
      eventName: "personal_back_click",
      page: "personal",
      block: "back",
    },
  },
  "Register:Personal:Info:Personal:Click": {
    ya: {
      eventName: "personal_infoPersonal_click",
      page: "personal",
      element: "infoPersonal",
    },
  },
  "Register:Personal:Info:Rules:Click": {
    ya: {
      eventName: "personal_infoRules_click",
      page: "personal",
      element: "infoRules",
    },
  },
  "Register:Success": {
    ya: {
      eventName: "reg_success",
    },
  },
};
