import type { IUserPermissionsResponseKeys } from "~/typings/api/user";
import type { IUserPermissionsKeys } from "~/typings/user";

export const userPermissionsApiKeyMap = {
  push_pers: "isPushPersPermitted",
  push_mass: "isPushMassPermitted",
  push_content: "isPushContentPermitted",
  email: "isEmailPermitted",
  sms: "isSmsPermitted",
  social: "isSocialPermitted",
  calls: "isCallsPermitted",
  digital_receipt: "isDigitalReceiptPermitted",
} as Record<IUserPermissionsResponseKeys, IUserPermissionsKeys>;
