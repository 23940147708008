import type { FetchError } from "ofetch";
import { useRouter } from "vue-router";
import Cookie from "@magnit/core/src/utilities/cookie";
import { ErrorCodes, HttpCodes } from "~/api/config";
import { storage } from "~/utils/consts";
import { privateRoutes, Routes } from "~/utils/routes";

const useResponseCheck = () => {
  const router = useRouter();
  const route = useRoute();

  const hasError = async (error: FetchError) => {
    /**
     * ErrorCodes.UserNotFound возвращается в ручке получения баланса.
     * Не совсем верно проверять дорегистрацию по ней, но других вариантов для фикса в моменте нет
     */
    if (
      error.statusCode === HttpCodes.UnprocessableEntity &&
      [ErrorCodes.IncompleteProfile, ErrorCodes.UserNotFound].includes(error.data?.code)
    ) {
      Cookie.set(storage.incompleteProfile, "1");

      if (privateRoutes.includes(route.path)) {
        await router.push(Routes.ProfileIncomplete);
      }

      return true;
    }

    return false;
  };

  return {
    hasError,
  };
};

export default useResponseCheck;
