// todo: когда-то добраться и перенести сюда остальные запросы из стора юзера
import type { IUserPermissionsResponse } from "~/typings/api/user";

type IPermissionsRequestApplication = "loyalty" | "V1" | "market";

const getUpdatePermissionsParams = (patch: Partial<IUserPermissionsResponse>, options = {}) => ({
  method: "PUT" as const,
  body: {
    application: "V1" as IPermissionsRequestApplication,
    ...renameKeys(patch, userPermissionsApiKeyMap, true),
  },
  permissions: {
    jwt: true,
  },
  ...options,
});

const getPermissionsParams = (options = {}) => ({
  permissions: {
    jwt: true,
  },
  query: { application: "V1" as IPermissionsRequestApplication },
  ...options,
});

export const requestPermissions = (options = {}) => {
  return useTransport<IUserPermissionsResponse>(urls.user.permissions, getPermissionsParams(options));
};

export const requestUpdatePermissions = (patch: Partial<IUserPermissionsResponse>, options = {}) => {
  return useTransport<IUserPermissionsResponse>(urls.user.permissions, getUpdatePermissionsParams(patch, options));
};
