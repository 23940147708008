import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Coupon:Page:View": {
    ya: {
      eventName: "coupDetail_view",
      page: "coupDetail",
    },
  },
  "Coupon:Page:Get:Click": {
    ya: {
      eventName: "coupDetail_get_click",
      page: "coupDetail",
      element: "get",
    },
  },
  "Coupon:Page:HowGet:Click": {
    ya: {
      eventName: "coupDetail_howGet_click",
      page: "coupDetail",
      element: "howGet",
    },
  },
  "Coupon:Page:Favorite:Click": {
    ya: {
      eventName: "coupDetail_like_click",
      page: "coupDetail",
      element: "like",
    },
  },
  "Coupon:Page:ShowCard:Click": {
    ya: {
      eventName: "coupDetail_showCard_click",
      page: "coupDetail",
      element: "showCard",
    },
  },
  "Coupon:Page:MainCard:View": {
    ya: {
      eventName: "coupDetail_card_view",
      page: "coupDetail",
      block: "card",
    },
  },
  "Coupon:Page:MainCard:Close:Click": {
    ya: {
      eventName: "coupDetail_card_close_click",
      page: "coupDetail",
      block: "card",
      element: "close",
    },
  },
  "Coupons:Page:View": {
    ya: {
      eventName: "coupMain_view",
      page: "coupMain",
    },
  },
  "Coupons:Page:Tabs:Item:Click": {
    ya: {
      eventName: "coupMain_tapbar_tab_click",
      page: "coupMain",
      block: "tapbar",
      element: "tab",
    },
  },
  "Coupons:Page:Back:Click": {
    ya: {
      eventName: "coupMain_back_click",
      page: "coupMain",
      element: "back",
    },
  },
  "Coupons:Page:Favorite:Click": {
    ya: {
      eventName: "coupDetail_like_click",
      page: "coupMain",
      element: "like",
    },
  },
  "Coupons:Page:Item:View": {
    ya: {
      eventName: "coupMain_item_view",
      page: "coupMain",
      element: "item",
    },
  },
  "Coupons:Page:Item:Click": {
    ya: {
      eventName: "coupMain_item_click",
      page: "coupMain",
      element: "item",
    },
  },
  "Coupons:Page:Empty:Click": {
    ya: {
      eventName: "coupMain_showCoupons_click",
      page: "coupMain",
      element: "showCoupons",
    },
  },
};
