<script setup lang="ts">
import { computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useClipboard } from "@vueuse/core";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import Error from "~/assets/svg/icons/error-sad.svg";
import LogoIcon from "~/assets/svg/icons/v1-logo-card.svg";
import CopyIcon from "~/assets/svg/icons/copy.svg";
import VQrCode from "~/components/VQrCode.vue";
import useQrCodeError from "~/composables/useQrCodeError";
import { useUserStore } from "~/store/user";

interface IQrCardProps {
  modal?: boolean;
}

const userStore = useUserStore();
const router = useRouter();
const { $toast } = useNuxtApp();
const { copy, copied } = useClipboard();
const { error, reloadAfterError } = useQrCodeError();
const { send } = AnalyticManager;

const props = defineProps<IQrCardProps>();

const cardNumber = computed<string>(() => userStore.profile.cardNumber);

const tryAgain = async () => {
  await userStore.getQR();
};

const onQrVisible = () => {
  if (!props.modal) {
    send("QR:Page:Code:View");
  }
};

onMounted(async () => {
  if (
    userStore.status.qrcode !== "success" &&
    userStore.status.qrcode !== "pending"
  ) {
    await userStore.getQR();
  }
});

watch(copied, (next) => {
  if (next) {
    if (!props.modal) {
      send("QR:Page:Number:Copy");
    }
    $toast.success({ title: "Номер карты скопирован" });
  }
});

watch(error, (next) => {
  if (next) {
    if (!props.modal) {
      send("QR:Page:Error:View", {
        error_type: userStore.status.qrcodeError,
      });
    }
  }
});

watch(cardNumber, (next) => {
  if (next) {
    if (!props.modal) {
      send("QR:Page:Number:View");
    }
  }
});
</script>

<template>
  <div class="qr-card">
    <div
      v-if="error"
      class="qr-card__error"
      :class="{ 'qr-card__error_page': !modal }"
    >
      <Error />
      <VText class="qr-card__error_title" font="title-large">
        Не удалось загрузить QR-код и номер карты
      </VText>
      <VText>Попробуйте ещё раз или повторите попытку позже</VText>
      <div v-if="!modal" class="qr-card__error_buttons">
        <VButton full-width :loading="reloadAfterError" @click="tryAgain">
          Попробовать ещё раз
        </VButton>
        <VButton full-width theme="secondary" @click="router.back">
          Назад
        </VButton>
      </div>
    </div>
    <div v-else class="qr-card__success">
      <img
        class="qr-card__title-image"
        src="/images/card/card_title.webp"
        alt="Первый выбор - В1"
      >
      <VText tag="div" class="qr-card__text">
        Покажите QR-код на кассе
      </VText>
      <div class="qr-card__code-wrapper">
        <div class="qr-card__code-wrapper-code">
          <VQrCode
            :size="200"
            :qrcode="userStore.qrcode"
            @visible="onQrVisible"
          />
        </div>
        <div class="qr-card__code-wrapper-description">
          <LogoIcon />
          <VText
            class="qr-card__code-wrapper-description-text"
            color="invert-primary"
          >
            {{ formatCreditCard(cardNumber) }}
          </VText>
          <CopyIcon
            :class="{
              'qr-card__code-wrapper-description-text-copied': copied,
            }"
            @click="copy(cardNumber)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.qr-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--pl-unit-x4) 0;
  height: 100%;

  img {
    display: block;
    max-width: 100%;
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto;

    svg {
      margin-bottom: var(--pl-unit-x5);
    }

    &_title {
      margin-bottom: var(--pl-unit-x4);
    }

    &_page {
      padding: var(--pl-unit-x4) var(--pl-unit-x5);
    }

    &_buttons {
      width: 100%;
      margin-top: var(--pl-unit-x5);
    }

    button:first-of-type {
      margin-bottom: var(--pl-unit-x2);
    }
  }

  &__title-image {
    width: 138px;
    margin: 0 auto var(--pl-unit-x2);
    padding: 6px 0;
  }

  &__text {
    font: var(--pl-font-body-large-accent-low);
    padding: var(--pl-unit-x2);
    margin-bottom: var(--pl-unit-x3);
  }

  &__code-wrapper {
    display: flex;
    flex-direction: column;
    padding: var(--pl-unit-x4);
    border-radius: 4px;
    background: #e5143d;

    &-description {
      display: flex;
      align-items: center;
      margin-top: var(--pl-unit-x3);
      justify-content: center;
      gap: var(--pl-unit-x2);

      svg {
        color: white;
        padding: 0 2px;
      }

      &-text {
        flex-grow: 1;
        font: var(--pl-font-body-large-accent-low);

        &-copied {
          animation: qr-card-copy 0.3s ease-out;
        }
      }
    }

    &-code {
      background: white;
      border-radius: 2px;
      padding: var(--pl-unit-x5);
      display: flex;
      justify-content: center;
    }
  }
}

@keyframes qr-card-copy {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(1.8);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
</style>
