import { isEqual } from "lodash-es";
import type { IAnyObject } from "~/typings/object";

export function removeEmptyOptionalProperties<T>(
  obj: T extends unknown ? any : any,
): T {
  const result = {} as T;
  Object.keys(obj).forEach((prop) => {
    const key = prop as keyof T;

    if (obj[prop] || obj[prop] === false || obj[prop] === 0) {
      result[key] = obj[prop] as T[typeof key];
    }
  });
  return { ...result };
}

export const shallowDiff = (
  object1: IAnyObject,
  object2: IAnyObject,
): IAnyObject =>
  Object.entries(object1).reduce(
    (diff, [key, value]) =>
      isEqual(object2[key], value) ? diff : { ...diff, [key]: value },
    {},
  );

/**
 * Функция переименовывает ключи принимаемого объекта на основе маппинга
 * @param obj исходный объект, ключи которого нужно переименовать
 * @param keyMap объект, который содержит соответствия между старыми и новыми ключами
 * @param reverse доп. параметр, определяет, нужно ли перевернуть ключи и значения объекта соответствий keyMap
 * @example
 * const originalObject = {firstName: "John", lastName: "Doe"};
 * const keyMapping = {firstName: "first_name", lastName: "last_name"};
 * const renamedObject = renameKeys(originalObject, keyMapping);
 * console.log(renamedObject); // { first_name: 'John', last_name: 'Doe' }
 */
export function renameKeys<T extends Record<string, any>, K extends Record<string, string>>(
  obj: T,
  keyMap: K,
  reverse: boolean = false,
): { [P in keyof T as P extends keyof K ? K[P] : P]: T[P] } {
  const localKeyMap = reverse
    ? Object.fromEntries(Object.entries(keyMap).map(([key, value]) => [value, key])) as any
    : keyMap;

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key in localKeyMap ? localKeyMap[key as keyof K] : key,
      value,
    ]),
  ) as any;
}
