import { MODALS, useModalsStore } from "~/store/modals";

const getModal = (modal: MODALS | string) => {
  const modalsStore = useModalsStore();
  return {
    name: modal,
    open: (clearable?: boolean, manual?: boolean) =>
      modalsStore.open(modal, clearable, manual),
    close: () => modalsStore.close(modal),
    isOpen: computed(() => modalsStore.isOpen(modal)),
    isActive: computed(() => modalsStore.getActive() === modal),
  };
};

export const useDefaultModal = (modal: string) => getModal(modal);
export const useLoginModal = () => getModal(MODALS.Login);
export const useLogoutModal = () => getModal(MODALS.Logout);
export const useCalendarModal = () => getModal(MODALS.Calendar);
export const useCatalogFiltersModal = () => getModal(MODALS.CatalogFilters);
export const useShareModal = () => getModal(MODALS.Share);
export const useCardModal = () => getModal(MODALS.Card);
export const useEmailConfirmModal = () => getModal(MODALS.EmailConfirm);
export const useDigitalReceiptModal = () => getModal(MODALS.DigitalReceipt);
export const useCardGuideModal = () => getModal(MODALS.CardGuide);
export const useLoyaltyProgramReminderModal = () =>
  getModal(MODALS.LoyaltyProgramReminder);
export const useCardMergeDelayModal = () => getModal(MODALS.CardMergeDelay);
export const useStoresModal = () => getModal(MODALS.StoresModal);
export const usePushInstructionsModal = () => getModal(MODALS.PushInstruction);
export const usePwaIosInstructionModal = () =>
  getModal(MODALS.PwaIosInstruction);
export const useLoyaltyProgramModal = () => getModal(MODALS.LoyaltyProgram);
export const useMechanicsPriceComparisonModal = () => getModal(MODALS.MechanicsPriceComparisonModal);
export const usePDNModal = () => getModal(MODALS.PDN);
export const useCitySelectModal = () => getModal(MODALS.CitySelect);
export const useRateSuccessModal = () => getModal(MODALS.RateSuccess);
export const useRateErrorModal = () => getModal(MODALS.RateError);
export const useStorePromptModal = () => getModal(MODALS.StorePrompt);
export const useProductsModal = () => getModal(MODALS.Products);
