import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useRuntimeConfig } from "nuxt/app";
import type { IExperimentsAndFeatures } from "~/typings/api/experiments";
import experimentsApi from "~/api/experimentsApi";

interface IExperimentsStatus {
  experiments: "initial" | "pending" | "success" | "error";
}

export const useExperimentsStore = defineStore("experiments", () => {
  const { requestExperiments } = experimentsApi();
  const experimentsNamespace = useRuntimeConfig().public.experimentsNamespace ?? "";

  const status = reactive<IExperimentsStatus>({
    experiments: "initial",
  });
  const experiments = ref<number[]>([]);
  const features = ref<IExperimentsAndFeatures["features"]>([]);

  const hasExperiment = (groupId: number) => experiments.value.includes(groupId);
  const hasFeature = (featureName: string, groupName: string) => {
    return features.value.some((feature) => feature.featureName === featureName && feature.groupName === groupName);
  };

  const getExperiments = async () => {
    status.experiments = "pending";

    const savedUserId = useLocalStorage<{
      uuid: string;
    }>(storage.uuid, {
      uuid: "",
    });

    const splitId = savedUserId.value.uuid || "";

    const { data, error } = await requestExperiments(splitId, experimentsNamespace);
    if (error.value) {
      status.experiments = "error";
      return;
    }
    if (data.value) {
      experiments.value = data.value?.activeExperimentsGroupIds ?? [];
      features.value = data.value?.features ?? [];
      status.experiments = "success";
    }
  };

  const getExperimentsIfNeeded = async () => {
    if (["pending", "success"].includes(status.experiments)) {
      return;
    }
    await getExperiments();
  };

  const experimentsForAnalytics = computed<string | null>(() => experiments.value.join(";") || null);

  const hasExperimentProductsRating = computed<boolean>(() => hasFeature("V1_UR_products_rating", "test"));

  return {
    hasExperiment,
    hasFeature,
    hasExperimentProductsRating,
    getExperiments,
    getExperimentsIfNeeded,
    status,
    experimentsForAnalytics,
  };
});
