import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Auth:PhoneNumber:View": {
    ya: {
      eventName: "phoneNumber_view",
      page: "phoneNumber",
    },
  },
  "Auth:PhoneNumber:Input:Click": {
    ya: {
      eventName: "phoneNumber_enterPhone_click",
      page: "phoneNumber",
      element: "enterPhone",
    },
  },
  "Auth:PhoneNumber:Continue:Click": {
    ya: {
      eventName: "phoneNumber_continue_click",
      page: "phoneNumber",
      element: "continue",
    },
  },
  "Auth:PhoneNumber:Captcha:Click": {
    ya: {
      eventName: "phoneNumber_сaptcha_click",
      page: "phoneNumber",
      element: "сaptcha",
    },
  },
  "Auth:PhoneNumber:Close:Click": {
    ya: {
      eventName: "phoneNumber_close_click",
      page: "phoneNumber",
      element: "close",
    },
  },
  "Auth:Captcha:View": {
    ya: {
      eventName: "captcha_view",
      page: "captcha",
    },
  },
  "Auth:Captcha:Ok:Click": {
    ya: {
      eventName: "captcha_ok_click",
      page: "captcha",
      element: "ok",
    },
  },
  "Auth:Captcha:Close:Click": {
    ya: {
      eventName: "captcha_back_click",
      page: "captcha",
      element: "close",
    },
  },
  "Auth:PhoneConfirm:View": {
    ya: {
      eventName: "phoneConfirm_view",
      page: "phoneConfirm",
    },
  },
  "Auth:PhoneConfirm:Back:Click": {
    ya: {
      eventName: "phoneConfirm_back_click",
      page: "phoneConfirm",
      element: "back",
    },
  },
  "Auth:PhoneConfirm:Close:Click": {
    ya: {
      eventName: "phoneConfirm_close_click",
      page: "phoneConfirm",
      element: "close",
    },
  },
  "Auth:PhoneConfirm:ReSubmit:Click": {
    ya: {
      eventName: "phoneConfirm_resubmit_click",
      page: "phoneConfirm",
      element: "resubmit",
    },
  },
  "Auth:Success": {
    ya: {
      eventName: "auth_success_visible",
    },
  },
  "Auth:PhoneNumber:Error:View": {
    ya: {
      eventName: "phoneNumber_error_view",
      page: "phoneNumber",
      block: "error",
    },
  },
  "Auth:PhoneConfirm:Error:View": {
    ya: {
      eventName: "phoneConfirm_error_view",
      page: "phoneConfirm",
      block: "error",
    },
  },
  "Auth:Validation:Error:View": {
    ya: {
      eventName: "validation_error_view",
      page: "validation",
      block: "error",
    },
  },
};
