import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Modals:CardGuide:View": {
    ya: {
      eventName: "addCard_whereQRInfo_view",
      page: "addCard",
      block: "whereQRInfo",
    },
  },
  "Modals:DigitalReceipt:View": {
    ya: {
      eventName: "settMain_checkOnlineTst_view",
      page: "settMain",
      block: "checkOnlineTst",
    },
  },
  "Modals:DigitalReceipt:Toggle": {
    ya: {
      eventName: "settMain_checkOnlineTst_turn_click",
      page: "settMain",
      block: "checkOnlineTst",
      element: "turn",
    },
  },
  "Modals:DigitalReceipt:Close": {
    ya: {
      eventName: "settMain_checkOnlineTst_close_click",
      page: "settMain",
      block: "checkOnline",
      element: "close",
    },
  },
  "Modals:PushOn:View": {
    ya: {
      eventName: "settNotice_popUpPushPerm_view",
      page: "settNotice",
      block: "popUpPushPerm",
    },
  },
  "Modals:PushOn:Ok": {
    ya: {
      eventName: "settNotice_popUpPushPerm_ok_click",
      page: "settNotice",
      block: "popUpPushPerm",
      element: "ok",
    },
  },
  "Modals:PushOn:Later": {
    ya: {
      eventName: "settNotice_popUpPushPerm_later_click",
      page: "settNotice",
      block: "popUpPushPerm",
      element: "later",
    },
  },
  "Modals:Logout:View": {
    ya: {
      eventName: "settMain_LogOutPopUp_view",
      page: "settMain",
      block: "LogOutPopUp",
    },
  },
  "Modals:Logout:Ok": {
    ya: {
      eventName: "settMain_LogOutPopUp_ok_click",
      page: "settMain",
      block: "LogOutPopUp",
      element: "ok",
    },
  },
  "Modals:Logout:Cancel": {
    ya: {
      eventName: "settMain_LogOutPopUp_cancel_click",
      page: "settMain",
      block: "LogOutPopUp",
      element: "cancel",
    },
  },
};
