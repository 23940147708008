<script setup lang="ts">
import VBlockLoader from "@magnit/core/src/components/VLoader/VBlockLoader.vue";
import VCookiePolicyPopup from "@magnit/unit-cookie-policy/src/VCookiePolicyPopup.vue";
import VLandingHeader from "~/containers/landing/VLandingHeader.vue";
import VNotificationsManager from "~/components/VNotificationsManager.vue";
import VPwaIosInstructionModal from "~/containers/Modals/VPwaIosInstructionModal.vue";

useHead({
  link: [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossorigin: "",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Martian+Mono:wght@800&display=swap",
    },
  ],
});

useSeoMeta({
  title: "В1 - Первый выбор – Выгодный продуктовый! – Официальный сайт",
  description:
    "Официальный сайт новой сети продуктовых магазинов «Первый выбор»",
  keywords:
    "В1, первый выбор, скидки, выгодный, покупки, свежий хлеб, хлебо-булочные изделия, пекарня в магазине, фрукты, овощи, мясо, молочные продукты, колбасные изделия, низкие цены, высокое качество, все необходимые продукты, экономные покупки",
});

const landingHeaderHeightDefault = "58px";
const landingHeader = ref(null);
const { height } = useElementSize(landingHeader);
const headerHeight = computed(() =>
  !height.value ? landingHeaderHeightDefault : `${height.value}px`,
);
const { displayLoader } = storeToRefs(useAppStore());
</script>

<template>
  <Body class="v1-landing" />
  <VLandingHeader ref="landingHeader" />
  <VPwaIosInstructionModal />
  <main :style="{ paddingTop: headerHeight }">
    <slot />
  </main>
  <VCookiePolicyPopup />
  <VNotificationsManager key="app-notifications-manager" />
  <VBlockLoader :show="displayLoader" page />
</template>

<style lang="postcss">
@import url("assets/css/landing/global.css");

.v1-landing {
  --v1-landing-header: 58px;
  --v1-landing-color-red: rgb(241 15 64 / 100%);
  --v1-landing-color-green: rgb(18 140 7 / 100%);

  font-family: Inter, sans-serif;
  line-height: normal;

  .font-custom {
    font-family: Custom, sans-serif;
  }

  .color-red {
    color: var(--v1-landing-color-red);
  }

  .color-green {
    color: var(--v1-landing-color-green);
  }

  * {
    scroll-margin-top: var(--v1-landing-header);
  }

  main {
    min-height: 100%;
    position: relative;
  }
}
</style>
