import type { IAnaliticEventsApi } from "@magnit/analytic-events";

export default <Record<string, IAnaliticEventsApi>>{
  "Profile:Legal:View": {
    ya: {
      eventName: "infoLegal_view",
      page: "infoLegal",
    },
  },
  "Profile:Legal:Terms:Click": {
    ya: {
      eventName: "infoLegal_loyaltyProg_click",
      page: "infoLegal",
      element: "loyaltyProg",
    },
  },
  "Profile:Legal:Cookies:Click": {
    ya: {
      eventName: "infoLegal_cookies_click",
      page: "infoLegal",
      element: "cookies",
    },
  },
  "Legal:MechanicsPriceComparison:Click": {
    ya: {
      eventName: "infoLegal_mechanicsPriceComparison_click",
      page: "infoLegal",
      element: "mechanicsPriceComparison",
    },
  },
  "Profile:Legal:Personal:Click": {
    ya: {
      eventName: "infoLegal_persData_click",
      page: "infoLegal",
      element: "persData",
    },
  },
  "Profile:Legal:Back": {
    ya: {
      eventName: "infoLegal_back_click",
      page: "infoLegal",
      element: "back",
    },
  },
};
